// Footer Pop Out
function openPopUpUrl(e,t,a){return window.open(e,"","height="+t+", width="+a+", scrollbars=yes, resizable=yes");}

// Slide One Slider
var mySwiper = undefined;

function initSwiper() {
    var screenWidth = $(window).width();
    if (screenWidth < 768 && mySwiper == undefined) {
        mySwiper = new Swiper('#help-slider', {
            autoHeight: true,
            pagination: {
                el: '.help-swiper-pagination'
            },
        });
    } else if (screenWidth > 767 && mySwiper != undefined) {
        mySwiper.destroy();
        mySwiper = undefined;
        jQuery('.swiper-wrapper').removeAttr('style');
        jQuery('.swiper-slide').removeAttr('style');
    }
}
var mySwiper1 = undefined;

function initSwiper1() {
    var screenWidth = $(window).width();
    if (screenWidth < 992 && mySwiper1 == undefined) {
        mySwiper1 = new Swiper('#benefit-slider', {
            slidesPerView: 3,
            slidesPerGroup: 3,
            grabCursor: true,
            pagination: {
                el: '.benefit-swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                767: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    autoHeight: true
                }
            },

        });
    } else if (screenWidth > 991 && mySwiper1 != undefined) {
        mySwiper1.destroy();
        mySwiper1 = undefined;
        jQuery('.swiper-wrapper').removeAttr('style');
        jQuery('.swiper-slide').removeAttr('style');
    }
}
var mySwiper2 = undefined;

function initSwiper2() {
    var screenWidth = $(window).width();
    if (screenWidth < 992 && mySwiper2 == undefined) {
        mySwiper2 = new Swiper('#patent-slider', {
            grabCursor: true,

            pagination: {
                el: '.patent-swiper-pagination'
            },
            breakpoints: {
                767: {
                    autoHeight: true
                }
            }
        });
    } else if (screenWidth > 991 && mySwiper2 != undefined) {
        mySwiper2.destroy();
        mySwiper2 = undefined;
        jQuery('.swiper-wrapper').removeAttr('style');
        jQuery('.swiper-slide').removeAttr('style');
    }
}

var mySwiper3 = undefined;

function initSwiper3() {
    var screenWidth = $(window).width();
    if (screenWidth < 992 && mySwiper3 == undefined) {
        mySwiper3 = new Swiper('#join', {
            slidesPerView: 1,
            grabCursor: true,
            pagination: {
                el: '.join-swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                767: {
                    autoHeight: true
                }
            }
        });
    } else if (screenWidth > 991 && mySwiper3 != undefined) {
        mySwiper3.destroy();
        mySwiper3 = undefined;
        jQuery('.swiper-wrapper').removeAttr('style');
        jQuery('.swiper-slide').removeAttr('style');
    }
}
//Swiper plugin initialization
initSwiper();
initSwiper1();
initSwiper2();
initSwiper3();

//Swiper plugin initialization on window resize
$(window).on('resize', function () {
    initSwiper();
    initSwiper1();
    initSwiper2();
    initSwiper3();
});

var swiper = new Swiper('#patent-slider-mobile', {
    slidesPerView: 1,
    grabCursor: true,
    autoHeight: true,
    pagination: {
        el: '.patent-swiper-pagination',
        clickable: true,
    }
});

var swiper1 = new Swiper('#join-mobile', {
    slidesPerView: 1,
    grabCursor: true,
    autoHeight: true,
    pagination: {
        el: '.join-swiper-pagination',
        clickable: true,
    }
});

// Add smooth scrolling to all links
$("#menu .navbar-nav > li > a").on('click', function (event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 800, function () {

            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
        });
    } // End if
});